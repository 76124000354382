<template>
  <v-layout row wrap class="text-center">
    <v-flex xs12>
      <div class="sw-h3 font-weight-light">
        {{ $t("group_message_template_wizard.message_created") }}
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
export default {};
</script>
