<template>
  <v-layout row wrap>
    <v-flex class="xs8 pr-5">
      <v-layout row wrap>
        <v-flex class="xs12 mb-5">
          <div class="sw-h5">
            {{ $t("group_message_template_wizard.add_audience") }}
          </div>
          <AudienceSelector v-model="messageTemplate.audiences" />
        </v-flex>
        <v-flex class="xs12 mb-5">
          <div class="sw-h5">
            {{ $t("group_message_template_wizard.add_guests") }}
          </div>
          <RecipientSelector v-model="messageTemplate.recipients" />
        </v-flex>
        <v-flex class="xs12">
          <v-layout row wrap class="mb-5 pr-5">
            <v-flex xs12 class="mb-1 sw-h5">{{
              $t("group_message_template_wizard.send_test_message")
            }}</v-flex>
            <v-flex v-if="messageTemplate && messageTemplate.id" xs12>
              <TestMessages
                :group-id="groupId"
                :message-template-id="messageTemplate.id"
                :success-message="[
                  'group_message_template_wizard.test_message_success',
                  'lang',
                ]"
              />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import AudienceSelector from "@/components/AudienceSelector.vue";
import RecipientSelector from "@/components/RecipientSelector.vue";
import TestMessages from "@/components/TestMessages.vue";

export default {
  computed: {
    groupId() {
      return this.$route.params.group_id;
    },
    messageTemplate: {
      get() {
        return this.$store.getters.groupMessageTemplateWizardTemplate;
      },
      set(val) {
        this.$store.dispatch("setGroupMessageTemplateWizardTemplate", val);
      },
    },
  },
  components: {
    AudienceSelector,
    RecipientSelector,
    TestMessages,
  },
};
</script>

<style lang="scss" scoped></style>
