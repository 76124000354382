<template>
  <v-container class="general">
    <page-title :component="'MessageListPage'">
      <template v-slot:actions>
        <LanguageSwitcher
          v-if="activeStep !== 'done'"
          ref="languageSwitcher"
          :events="activeStep !== 'confirmation'"
          @click="changeNext('stay')"
          :loading="isLoading"
        />
        <v-btn
          v-if="activeStep === 'done'"
          round
          class="sw-secondary-bg sw-on-secondary text-none ml-3"
          @click="routeToOverview"
        >
          {{ $t("group_message_template_wizard.go_to_overview") }}
        </v-btn>
      </template>
    </page-title>
    <AppStepperHeader v-model="stepper" :steps="steps" :loading="isLoading" />
    <v-window v-model="stepper" class="mb-5 no-transition">
      <v-window-item
        v-for="(step, i) in steps"
        :key="`content-${i}`"
        :value="i"
        :class="{ loading: isLoading }"
      >
        <component :is="step.component" :group-id="groupId"></component>
      </v-window-item>
    </v-window>
    <!-- Actions -->
    <div v-if="stepper < steps.length - 1">
      <v-btn
        round
        large
        class="ml-0 mr-3 white sw-accent text-none"
        @click="routeToOverview"
        >{{ $t("common.cancel") }}</v-btn
      >
      <v-btn
        round
        large
        class="ml-0 mr-3 sw-accent-bg sw-on-accent text-none"
        :loading="isLoading"
        @click="changeNext()"
        >{{ nextButtonText }}</v-btn
      >
    </div>
  </v-container>
</template>

<script>
import moment from "moment-timezone";
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";
import AppStepperHeader from "@/components/AppStepperHeader.vue";
import MessageTemplate from "@/components/GroupMessageTemplateWizard/MessageTemplate.vue";
import Receivers from "@/components/GroupMessageTemplateWizard/Receivers.vue";
import Done from "@/components/GroupMessageTemplateWizard/Done.vue";

export default {
  provide() {
    return {
      parentValidator: this.$validator,
    };
  },
  data: () => ({
    isLoading: false,
    stepper: 0,
  }),
  computed: {
    groupId() {
      return this.$route.params.group_id;
    },
    groupPluginId() {
      return this.$route.params.group_plugin_id;
    },
    messageTemplateId() {
      return this.$route.params.message_id;
    },
    messageType() {
      return this.$route.query.type;
    },
    appContentLanguage() {
      return this.$store.getters.appContentLanguage;
    },
    activeGroup() {
      return this.$store.getters.activeGroup;
    },
    messageTemplate: {
      get() {
        return this.$store.getters.groupMessageTemplateWizardTemplate;
      },
      set(val) {
        this.$store.dispatch("setGroupMessageTemplateWizardTemplate", val);
      },
    },
    steps() {
      return [
        {
          name: "message",
          title: this.$t("group_message_template_wizard.message_settings"),
          component: MessageTemplate,
        },
        {
          name: "receivers",
          title: this.$t("group_message_template_wizard.add_receivers"),
          component: Receivers,
        },
        {
          name: "done",
          title: this.$t("group_message_template_wizard.done"),
          component: Done,
        },
      ];
    },
    activeStep() {
      return this.steps[this.stepper].name;
    },
    nextButtonText() {
      if (this.steps.length - this.stepper !== 2) {
        return this.$t("common.continue");
      }

      return this.$t("common.save");
    },
  },
  components: {
    LanguageSwitcher,
    AppStepperHeader,
  },
  created() {
    this.messageTemplate = {
      audiences: [],
      recipients: [],
      send_time_start: this.formatDate(moment()),
      send_time_end: null,
      is_hidden: 0,
    };
  },
  watch: {
    messageTemplateId: {
      immediate: true,
      handler() {
        this.getGroupMessageTemplate();
      },
    },
    appContentLanguage: {
      handler() {
        this.getGroupMessageTemplate();
      },
    },
    activeStep: {
      immediate: true,
      handler() {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      },
    },
  },
  methods: {
    routeToOverview() {
      this.$router.push(
        {
          name: "group_message_templates",
        },
        () => {},
      );
    },
    transformDestinationsForSave(value) {
      if (!value) return [];

      const destinations = [];

      for (const destination of value) {
        if (!destination) continue;

        let params = destination.params;

        if (Boolean(params) && typeof params == "object") {
          params = JSON.stringify(destination.params);
        }

        destinations.push({
          id: destination.id,
          component: destination.component,
          group_plugin_id: destination.group_plugin_id,
          name: destination.name,
          params,
        });
      }

      return destinations;
    },
    formatDate(date) {
      return date ? moment(date).format("YYYY-MM-DDTHH:mm:ssZ") : null;
    },
    transformTemplateForSave(value) {
      return {
        name: value.name,
        subject: value.subject,
        body: value.body,
        send_time_start: value.send_time_start
          ? this.formatDate(value.send_time_start)
          : this.formatDate(moment()),
        send_time_end: this.formatDate(value.send_time_end),
        message_image:
          value.message_image && value.message_image.id
            ? value.message_image.id
            : null,
        destinations: this.transformDestinationsForSave(value.destinations),
        is_hidden: value.is_hidden,
        active: value.active,
        lang: this.appContentLanguage,
      };
    },
    async getGroupMessageTemplate() {
      if (!this.messageTemplateId) return;

      try {
        this.isLoading = true;

        const params = [
          this.groupId,
          this.messageTemplateId,
          { lang: this.appContentLanguage },
        ];

        const response = await this.$api.groupMessageTemplates.get(...params);

        let messageTemplate = response.data.data;

        messageTemplate.destinations = (
          response.data.data.destinations || []
        ).map((destination) => ({
          component: destination.component,
          group_plugin_id: destination.group_plugin_id,
          label: destination.name,
          name: destination.name,
          params:
            typeof destination.params == "string"
              ? JSON.parse(destination.params)
              : destination.params,
        }));

        if (messageTemplate.message_image) {
          try {
            const response = await this.$api.media.get(
              messageTemplate.message_image,
            );

            messageTemplate.message_image = response.data.data;
          } catch (error) {
            if (error) {
              messageTemplate.message_image = null;
            }
          }
        }

        this.messageTemplate = messageTemplate;

        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
    async createGroupMessageTemplate() {
      const specs = [
        this.groupId,
        {
          type: this.messageType,
          group_plugin_id: this.groupPluginId,
          ...this.transformTemplateForSave(this.messageTemplate),
        },
      ];

      const response = await this.$api.groupMessageTemplates.create(...specs);

      const messageTemplate = response.data.data;

      if (messageTemplate.message_image) {
        try {
          const response = await this.$api.media.get(
            messageTemplate.message_image,
          );

          messageTemplate.message_image = response.data.data;
        } catch (error) {
          if (error) {
            messageTemplate.message_image = null;
          }
        }
      }

      this.messageTemplate = messageTemplate;

      this.$router.push(
        {
          name: "group_message_templates_edit",
          params: {
            group_id: this.groupId,
            group_plugin_id: this.groupPluginId,
            message_id: response.data.data.id,
          },
        },
        () => {},
      );
    },
    async updateGroupMessageTemplate() {
      const specs = [
        this.groupId,
        this.messageTemplateId,
        {
          audiences: this.messageTemplate.audiences
            ? this.messageTemplate.audiences.map((el) => el.id).toString()
            : null,
          recipients: this.messageTemplate.recipients
            ? this.messageTemplate.recipients.map((el) => el.id).toString()
            : null,
          ...this.transformTemplateForSave(this.messageTemplate),
        },
      ];

      await this.$api.groupMessageTemplates.update(...specs);
    },
    async changeNext(action) {
      if (this.activeStep === "message") {
        const isValid = await this.$validator.validate();

        if (!isValid) return;

        try {
          this.isLoading = true;

          if (!this.messageTemplateId) {
            await this.createGroupMessageTemplate();
          } else {
            await this.updateGroupMessageTemplate();
          }

          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
          return;
        }
      }

      if (this.activeStep === "receivers") {
        const isValid = await this.$validator.validate();

        if (!isValid) return;

        try {
          this.isLoading = true;

          await this.updateGroupMessageTemplate();

          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
          return;
        }
      }

      if (action !== "stay") {
        this.stepper += 1;
      } else {
        this.$refs.languageSwitcher.open();
      }
    },
  },
};
</script>

<style scoped>
.loading {
  opacity: 0.4;
  transition: all 0.2s;
  pointer-events: none;
}
</style>
